import "./htmx";
import 'htmx.org/dist/ext/ws';
import "flowbite";
import { DiscordSDK } from '@discord/embedded-app-sdk';

window.triggerLogin = async function(group) {
    let discordSdk = undefined;
    try {
        discordSdk = new DiscordSDK(process.env.DISCORD_CLIENT);
    } catch(e) {
        console.log('Not in discord activity, using battlenet auth.', e)
        window.location.href = `/login/battlenet?callback=/group/${group}`
        return
    }
    await discordSdk.ready();

    if (true) {
        discordSdk.close(1000, "No referee online")
    }

    // Pop open the OAuth permission modal and request for access to scopes listed in scope array below
    const { code } = await discordSdk.commands.authorize({
        client_id: process.env.DISCORD_CLIENT,
        response_type: 'code',
        state: '',
        prompt: 'Requesting access to connected BattleTag',
        scope: ['connections'],
    });
    window.location.href = `/login/activity?code=${code}&callback=/group/${group}`
}
